/****************** General ************************/
/***************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: #c9c6c4 !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top: 1px solid #c9c6c4;
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid #c9c6c4;
}

/* dates */
#alerts_module.list .title .date,
#events_module.cms_list .cms_date,
#feeds_module.cms_list .cms_list_item .cms_date h4,
#feeds_module.cms_entity .cms_date h4,
#jobs_module.cms_list .cms_list_item .cms_date h3,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3,
#photoalbums_module.cms_list .cms_list_item .cms_number_of_images, /* this is not a date but styled as one */
.posts-node .posts-field.posts-field-posted p,
#video_module.cms_list .cms_list_item .cms_date,
#video_module.cms_entity .cms_date h3,
#reservations_module.entity .metadata2 {
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 500;
	color: #2e2b2b;
}

/* ui form tags */
.ui-form-error {
	display: none;
}

.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: none;
	margin-top: 1rem !important;
	margin-bottom: 0.5rem !important;
	color: #60698e !important;
	font-size: 1.2rem !important;
	line-height: 1.5rem;
	font-weight: 500 !important;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #cc0000 !important;
	font-style: italic;
	font-family: Arial, sans-serif;
}

.required_information {
	font-family: Arial, sans-serif;
	color: #cc0000;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	font-size: 0.875rem;
	line-height: 1.42857143;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #cccccc;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-note {
	line-height: 1rem;
	margin-top: 5px;
}

@media (max-width: 991px) {
	.ui-form-fields {
		padding: 0px;
	}

	.ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-input {
		width: 100%;
	}

	.ui-form-input input[type="text"],
	.ui-form-input input[type="email"],
	.ui-form-input input[type="password"],
	.ui-form-input input[type="email"],
	.ui-form-input input[type="file"],
	.ui-form-input select,
	.ui-form-input textarea,
	.ui-form-fields .select2-container,
	.ui-form-fields .select2-container-multi,
	.ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}

	.ui-form-panel {
		padding: 0 !important;
	}

	.ui-form-buttons {
		text-align: left;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
		width: auto;
	}
}

/* pagination */
.pagination-wrapper .pagination-row td span[style="color:#999999;font-weight:bold;"] {
	color: #757575 !important;
	cursor: not-allowed;
}

/****************** Accounts ************************/
/***************************************************/
/*accounts*/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 365px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #c9c6c4;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #60698e;
	font-size: 1.714rem;
	line-height: 2.414rem;
	font-weight: 600;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #cc0000;
	font-style: italic;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 767px) {
	#account_addresses .address_section td {
		display: block;
		width: 100%;
	}

	#account_addresses .address_section td br {
		display: none;
	}

	#account_addresses .address_section td a + br {
		display: block;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/****************** Events *********************/
/***********************************************/
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .location-label-events-module {
	font-weight: 500;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
	font-size: 1rem;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	padding-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .event-category-label {
	margin-bottom: 10px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}


/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0;
}

#faqs_module .cms_list_item > ul {
	margin: 0;
	list-style-type: disc;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding: 0 0 10px;
	list-style-type: none;
}

/****************** News ***********************/
/***********************************************/
.box.archive {
	overflow: hidden;
}

#news_module.cms_list article {
	padding-bottom: 1px;
}

#news_module.cms_list .cms_categories_section .cms_category_item .image {
	margin-bottom: 10px;
}

#news_module.cms_list .cms_metadata1.cms_title h3 {
	margin-top: 0;
}

#news_module.cms_list article h2 {
	padding-top: 0;
}

.news_module.cms_category_list.header {
	margin-bottom: 20px;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0;
}

#news_module.cms_entity .quaternary_title {
	margin-top: 0;
}

.archive_links {
	overflow: hidden;
	margin-bottom: 0.75rem;
}

.archive_links a,
.archive_links a.selected {
	padding-bottom: 0;
}

@media (max-width: 991px) {
	#news_module.cms_entity .cms_content .image {
		float: none !important;
		margin-left: 0;
		height: auto !important;
	}

	#news_module.cms_list .cms_categories_row {
		margin-bottom: 20px;
	}
}


/******************* Posts *********************/
/***********************************************/
.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #2d3047;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #c9c6c4;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	min-width: 0;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

/* sidebar posts */
.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a,
.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed a {
	border-bottom: 0px;
}

.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.posts-blocks.posts-blocks-authors .posts-blocks-author,
.posts-blocks.posts-blocks-categories .posts-blocks-category,
.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 0.75em;
}

.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.posts-blocks-tags a {
	border-bottom-width: 0;
}

@media (min-width: 991px) {
	#posts_module.form .form_item .ui-form-input {
		display: inline-block;
		width: 100%;
	}

	#posts_module.form .form_item .ui-form-input input {
		width: calc(50% + 50px);
	}

	#posts_module.form .ui-form-buttons button {
		margin-right: 7px;
	}
}


/***************** Resources *******************/
/***********************************************/
#moduleDocuments_module.cms_list .cms_items .cms_item .cms_item_description {
	margin-top: 5px;
}

.moduleDocuments-rss-feed-link {
	border-bottom-width: 0 !important;
}

.box.tools a[href^="?tag"] {
	border-bottom-width: 0;
}

/*************** Submission Forms ***************/
/************************************************/
#submissionforms_module.cms_form .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .cms_required em {
	display: none;
}

#submissionforms_module.cms_form .required_information em {
	font-family: Arial, sans-serif;
	color: #cc0000;
}

#submissionforms_module.cms_form tr[data-required="true"] .cms_label label:after,
#submissionforms_module.cms_form tr[data-required="true"] .cms_label div:after {
	content: "*";
	color: #cc0000;
	padding-left: 5px;
	font-style: italic;
	font-size: 1.2rem;
	font-family: Arial, sans-serif;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 0px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

#submissionforms_module.cms_form .sigWrapper.current {
	height: 102px;
	width: 100%;
	max-width: 302px;
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input {
	display: block;
}

#submissionforms_module.cms_form .cms_field,
#submissionforms_module.cms_form .cms_required,
#submissionforms_module.cms_form .cms_label {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .formSection {
	margin-bottom: 15px;
}

#submissionforms_module.cms_form .cms_field .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 0 none;
	border-top: 1px solid #c9c6c4;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 40%;
	transform: translate(0px, -60%);
}

#submissionforms_module .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
	color: #2e2b2b;
}

#submissionforms_module .ui-form-label span {
	color: #2e2b2b;
	font-weight: 400;
}

#submissionforms_module .ui-form-label em {
	color: #cc0000;
	font-style: italic;
	font-family: Arial, sans-serif;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 26%;
}

.module-forms.confirmation .step-title {
	font-family: Raleway, sans-serif;
	color: #60698e;
	font-size: 1.714rem;
	line-height: 2.414rem;
	font-weight: 600;
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_required,
	#submissionforms_module.cms_form .cms_label {
		padding-bottom: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		padding-left: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label label::after,
	#submissionforms_module tr[data-required="true"] .cms_label div::after {
		content: "*";
		color: #cc0000;
		padding-left: 5px;
		font-family: Arial, sans-serif;
	}

	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 37%;
		left: 4px;
	}
}

/**************** Testimonials ******************/
/***********************************************/
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-link {
	border-bottom: 0px;
}


/******************* Search ************************/
/***************************************************/
.collection.search-collection > .node.search-node {
	border-top: 1px solid #c9c6c4;
	margin: 1.5em 0 0 0;
	padding: 1.5em 0 0 0;
}

.collection.search-collection > .node.search-node:first-of-type {
	border-top: 0 none;
	padding: 0;
}

.collection.search-collection .search-field-title {
	line-height: 1.6rem;
}

input[type="search"] {
	border: 1px solid #ccc;
}


/****************** Content Boxes *******************/
/***************************************************/
/* events */
#events_module.homepage_contentbox > br {
	display: none;
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 1.5em;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	padding-top: 7px;
	color: #60698e;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
}

#events_module.homepage_contentbox .event_list table tr td.title a {
	color: #60698e;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#events_module.homepage_contentbox .event_list table tr td.title a:hover {
	color: #60698e;
	cursor: pointer;
}

#events_module.homepage_contentbox .cms_description p {
	margin-bottom: 0;
}

#events_module.homepage_contentbox .footer {
	display: none;
}

@media (max-width: 991px) {
	#events_module.homepage_contentbox .event_list table tr td.date,
	#events_module.homepage_contentbox .event_list table tr td.title {
		display: block;
		width: 100%;
	}
}

/* news */
#news_module.homepage_contentbox.displaymode_list > a {
	display: none;
}

#news_module.homepage_contentbox .cms_title h3 {
	margin-top: 0;
	margin-bottom: 0;
}

#news_module.homepage_contentbox .cms_metadata2.cms_date h3 {
	line-height: 1.1rem;
}

/* posts */
.home-content-section .posts-collection .posts-node {
	padding: 9px 0 0;
	margin: 0;
}

.home-content-section .posts-field:last-of-type {
	display: none;
}

.home-content-section .posts-field a {
	color: #60698e;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.home-content-section .posts-field a:hover {
	color: #60698e;
	cursor: pointer;
}

/* submission forms */
#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

#submissionforms_module.homepage_contentbox + script + .home-content-box-learn-more {
	display: none;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}

/* testimonials */
#testimonials_module_contentbox.contentbox_item .company,
#testimonials_module_contentbox.contentbox_item .author-name {
	color: #60698e;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
	display: block;
}

#testimonials_module_contentbox .w-slide-testimonial {
	height: auto;
	margin-bottom: 30px;
}

#testimonials_module_contentbox .image-box {
	margin-bottom: 10px;
}

.slider-widget #testimonials_module_contentbox.contentbox_item {
	text-align: left;
	margin: 20px;
}
