body {
	font-family: Raleway, sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
}

.page-wrapper-full {
	color: #2e2b2b;
	font-family: inherit;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	color: #cd752f;
	font-size: 2.571rem;
	line-height: 3.271rem;
	font-weight: 500;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	color: #cd752f;
	font-size: 1.714rem;
	line-height: 2.414rem;
	font-weight: 600;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	color: #cd752f;
	font-size: 1.5rem;
	line-height: 2.2rem;
	font-weight: 600;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	color: #cd752f;
	font-size: 1.286rem;
	line-height: 1.986rem;
	font-weight: 600;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	color: #cd752f;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #cd752f;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 600;
	text-transform: uppercase;
}

p {
	margin-bottom: 1rem;
	line-height: 1.7rem;
}

body a {
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: underline;
}

ul {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding-left: 2.5rem;
}

ol {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 2.5rem;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 0.75rem 1.205rem 0.75rem 1.25rem;
	border-left: 5px solid #b22b25;
	background-color: #f4f4f4;
	color: #01579b;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 16px;
	border-radius: 2px;
	background-color: #60698e;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	cursor: pointer;
}

.primary,
.secondary,
.tertiary,
button[type="submit"] {
	padding: 9px 16px !important;
	border: 0 none !important;
}

.button:hover {
	background-color: #3b3b6b;
	background-image: none;
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	background-color: #3b3b6b !important;
	background-image: none;
}

.button:active,
.primary:active,
.secondary:active,
.tertiary:active,
button[type="submit"]:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.button.secondary,
.secondary,
button[type="submit"].secondary {
	border-top: 1px solid #007aef;
	border-right: 1px solid #007aef;
	border-bottom: 1px solid #007aef;
	background-color: #3b3b6b;
	background-image: none;
	color: #fff;
	text-align: center;
}

.button.secondary:hover {
	cursor: pointer;
	background-color: #60698e;
}

.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #60698e !important;
	cursor: pointer;
}

.tertiary,
button[type="submit"].tertiary {
	border-top: 1px solid #007aef;
	border-right: 1px solid #007aef;
	border-bottom: 1px solid #007aef;
	background-color: #3b3b6b;
	background-image: none;
	color: #ffffff;
	text-align: center;
}

.tertiary:hover,
button[type="submit"].tertiary:hover {
	background-color: #60698e !important;
	cursor: pointer;
}

.left-sidebar-custom a.primary,
.content-wrapper a.primary,
.left-sidebar-custom a.secondary,
.content-wrapper a.secondary,
.left-sidebar-custom a.tertiary,
.content-wrapper a.tertiary {
	color: #fff;
	cursor: pointer;
}

.left-sidebar-custom a.primary:hover,
.content-wrapper a.primary:hover,
.left-sidebar-custom a.secondary:hover,
.content-wrapper a.secondary:hover,
.left-sidebar-custom a.tertiary:hover,
.content-wrapper a.tertiary:hover {
	color: #fff;
	cursor: pointer;
}

.main-content {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	width: 100%;
}

.button-styles {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.form-styles {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container.content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	min-height: 500px;
	padding: 1.25rem 3vw 3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
}

.container.brand-container {
	display: block;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.nav-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 40px;
	margin-bottom: 0;
	padding-left: 0;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container.home-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 3rem;
	padding-left: 3rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.container.signoff-container {
	margin-top: 0rem;
	padding: 1rem 0rem 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.top-nav-container {
	overflow: hidden;
	width: 100%;
	max-height: 50px;
	height: 40px;
	max-width: none;
	padding-right: 3rem;
	padding-left: 3rem;
	background-color: #60698e;
	opacity: 1;
	-webkit-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.container.footer-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.container.events-container {
	max-width: none;
	padding-right: 3rem;
	padding-left: 3rem;
}

.container.cta-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.left-nav-col {
	width: 20%;
	min-width: 180px;
	margin-top: -2px;
	margin-right: 2rem;
	padding-top: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.content-col {
	width: 80%;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.content-col.no-left {
	width: 100%;
}

.content-col.no-left-right-columns {
	max-width: 1024px;
}

.breadcrumbs-wrapper {
	margin-top: 5px;
	font-size: 0.78rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumb-link {
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	border-right: 1px solid #bdbdbd;
	color: #424242;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumb-link:hover {
	color: #69b5a4;
	cursor: pointer;
}

.breadcrumb-current {
	display: inline-block;
	color: #738ac0;
	border-bottom: 1px solid transparent;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-side-col {
	width: 20%;
	max-width: 200px;
	min-width: 175px;
	margin-left: 2rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #2d3047;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col p {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #2d3047;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col h4 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #60698e;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.left-nav-section-title {
	margin-top: 0rem;
	font-size: 1rem;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-section-title-link {
	display: block;
	color: #60698e;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 700;
	letter-spacing: 0.02rem;
	text-decoration: none;
}

.left-nav-link {
	display: block;
	padding: 0.6rem 0.75rem;
	background-image: url('/20181011160742/assets/images/right-green-arrow-02.svg');
	background-position: -10px 50%;
	background-size: 13px 13px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-link:hover {
	display: block;
	margin-left: 0px;
	padding-right: 0.5rem;
	padding-left: 1rem;
	background-position: 0px 50%;
	color: #60698e;
}

.left-nav-link.selected,
.left-nav-link.w--current {
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 1rem;
	background-position: 0px 50%;
	color: #cd752f;
}

.left-nav-link.grandchild-link {
	background-size: 11px 11px;
	font-size: 0.9rem;
	line-height: 1.3rem;
}

.left-nav-link.grandchild-link.selected,
.left-nav-link.grandchild-link.w--current {
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 1rem;
}

.left-nav-link.greatgrandchild-link {
	background-size: 9px 9px;
	font-size: 0.83rem;
	line-height: 1.23rem;
}

.left-nav-link.greatgrandchild-link.selected,
.left-nav-link.greatgrandchild-link.w--current {
	padding-right: 0.5rem;
	padding-left: 1rem;
	font-size: 0.83rem;
	line-height: 1.23rem;
}

.header-section {
	position: fixed;
	z-index: 1;
	width: 100%;
	top: 0;
	border-bottom: 4px solid #cd752f;
	background-color: #fff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.navbar {
	padding-right: 3rem;
	padding-left: 3rem;
	background-color: transparent;
}

.logo {
	width: 100%;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.brand {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	height: auto;
	margin-right: 1rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	padding: 14px 0;
}

.nav-menu {
	display: block;
	margin-left: 2rem;
	float: right;
}

.main-nav-link {
	position: relative;
	display: block;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.6rem 0.75rem;
	-webkit-transition: color 300ms ease, font-size 300ms ease;
	transition: color 300ms ease, font-size 300ms ease;
	color: #60698e;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.dropdown-wrapper.has-dropdown:hover .main-nav-link,
.main-nav-link:hover {
	box-shadow: inset 0 3px 0 0 #cccccc;
	color: #cd752f;
}

.main-nav-link.active-item,
.main-nav-link.w--current {
	box-shadow: inset 0 3px 0 0 #cccccc;
	color: #cd752f;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.dropdown-wrapper {
	overflow: hidden;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	text-align: center;
}

.dropdown-list {
	position: absolute;
	overflow: hidden;
	height: 0px;
	max-width: 200px;
	min-width: 200px;
	margin-left: 0.75rem;
	padding: 0.5rem;
	background-color: #f3f2ee;
	opacity: 0;
	text-align: left;
	-webkit-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.open .dropdown-list {
	height: auto;
	opacity: 1;
}

.dropdown-ul {
	list-style-type: none;
	padding-left: 0;
	margin: 0;
}

.dropdown-ul a,
.dropdown-link {
	display: block;
	margin-top: 2px;
	margin-bottom: 2px;
	padding: 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #3b3b6b;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-decoration: none;
}

.dropdown-ul a:hover,
.dropdown-link:hover {
	background-color: #fff;
	color: #cd752f;
	font-weight: 400;
}

.dropdown-ul a.w--current,
.dropdown-link.w--current {
	background-color: #fff;
	color: #cd752f;
	cursor: pointer;
}

.rotator {
	height: 650px;
	background-color: #e2e2e2;
}

.slideshow-arrow {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.slidenav {
	opacity: 0.6;
	font-size: 0.75rem;
}

.slide-caption-wrapper {
	position: absolute;
	left: 0px;
	top: 40%;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 35%;
	margin-right: 0rem;
	margin-left: 4rem;
	padding: 0.5rem 1rem 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.slide-caption-header {
	margin-top: 0.5rem;
	color: #3b3b6b;
	font-size: 2.571rem;
	line-height: 3.171rem;
	font-weight: 300;
}

.slide-caption-paragraph p {
	margin-top: 1rem;
	color: #3b3b6b;
}

.slide-wrap {
	background-color: #fff;
	white-space: normal;
}

.footer-section,
.home-content-section {
	display: block;
	padding-top: 3rem;
	padding-bottom: 5rem;
	background-color: #fff;
}

.home-content-section.home-content-section-pattern-bg {
	display: block;
	padding-top: 4rem;
	padding-bottom: 4rem;
	border-top: 2px solid #e2e2e2;
	background-image: url('/20181011160742/assets/images/square-pattern.png');
	background-size: 150px;
	background-attachment: fixed;
}

.home-content-section.cta-section {
	padding-top: 0rem;
	padding-bottom: 0rem;
	border-top: 1px solid #f4f4f4;
	background-color: #fff;
	background-image: url('/20181011160742/assets/images/ama-pattern-sprinkles-bone50-01.png');
	background-position: 0px 0px;
	background-size: 368px;
	background-attachment: fixed;
}

.home-content-section.two-cb-home-content-section {
	padding-bottom: 4rem;
	border-top: 2px solid #e2e2e2;
	background-color: #fff;
}

.home-content-section.sponsors-section {
	padding-bottom: 5rem;
	background-color: #fff;
}

.home-content-section.sponsors-section .container {
	padding-left: 2rem;
	padding-right: 2rem;
}

.footer-section {
	padding-bottom: 0rem;
	border-top: 4px solid #60698e;
	background-color: #2d3047;
}

.cb-static {
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static._50percent-left {
	width: 50%;
	padding-right: 3rem;
}

.cb-static._50percent-right {
	width: 50%;
	padding-left: 3rem;
}

.cb-static.footer-cb-1 {
	width: 50%;
	padding-right: 0.5rem;
}

.cb-static.footer-cb-3 {
	width: 50%;
	padding-right: 2vw;
}

.cb-static.footer-cb-left-copy {
	width: 50%;
	padding-right: 0.5rem;
}

.cb-static.footer-cb-4 {
	width: 50%;
}

.event-image {
	height: 0px;
	margin-bottom: 1rem;
	padding: 31% 0px;
	background-color: #e0e0e0;
	background-image: url('/20181011160742/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.event-image:hover {
	background-size: 110%;
}

.event-image.event-image-3 {
	position: relative;
	background-image: url('/20181011160742/assets/images/1911h0072.jpg');
}

.event-image.event-image-2 {
	position: relative;
	background-image: url('/20181011160742/assets/images/131.jpg');
}

.event-image.event-image-1 {
	position: relative;
	background-image: url('/20181011160742/assets/images/businessladycomputer.jpg');
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	padding-left: 0;
	float: right;
	text-decoration: none;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogoText {
	line-height: 0.5rem;
}

#GRIPFooterLogoImg {
	margin-top: -19px !important;
}

.grip-logo-text-box {
	color: #fff;
	font-size: 0.5rem;
	line-height: 0.5rem;
	text-decoration: none;
	text-transform: uppercase;
}

.copyright-paragraph {
	display: inline-block;
}

.divider-bar-wrapper {
	display: block;
	width: 35px;
	height: 3px;
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	background-color: #ccc;
}

.right-side-col h2:after,
.right-side-col h3:after,
.right-side-col h4:after,
.divider-bar-wrapper.short-divider {
	content: '';
	display: block;
	width: 30px;
	height: 3px;
	margin-top: 0.65rem;
	margin-bottom: 0.65rem;
	background-color: rgba(147, 175, 241, 0.5);
	background-image: none;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading.clients-cb-head {
	display: block;
	margin-right: auto;
	margin-left: auto;
	color: #cd752f;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
	text-align: center;
}

.cb-heading.footer-heading {
	display: block;
	margin-bottom: 0.5rem;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1.95rem;
	font-weight: 600;
}

.cb-heading.cb-heading-secondary {
	color: #cd752f;
	font-weight: 400;
}

.homeboxes .cb-heading.cb-heading-secondary a {
	font-family: inherit;
	line-height: inherit !important;
	font-size: inherit;
	color: inherit !important;
	font-weight: inherit !important;
	border-bottom: 0px !important;
	text-decoration: none;
}

.cb-head-wrapper {
	display: block;
	margin-top: 2rem;
	margin-bottom: 1rem;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.rotator-arrow {
	display: block;
	width: 50px;
	height: 50px;
	margin-right: auto;
	margin-bottom: 0rem;
	margin-left: auto;
	background-image: url('/20181011160742/assets/images/left-circle-arrow-white-03.svg');
	background-position: 0px 50%;
	background-size: 30px;
	background-repeat: no-repeat;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.rotator-arrow:hover {
	background-image: url('/20181011160742/assets/images/left-circle-arrow-white-03-hover.svg');
}

.rotator-arrow.rotator-left-arrow {
	width: 50px;
	height: 50px;
	margin-bottom: 0rem;
	border-right-style: none;
	background-image: url('/20181011160742/assets/images/right-circle-arrow-white-03.svg');
	background-position: 100% 50%;
	background-size: 30px;
}

.rotator-arrow.rotator-left-arrow:hover {
	background-image: url('/20181011160742/assets/images/right-circle-arrow-white-03-hover.svg');
}

.cb-subhead {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	color: #2d3047;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
	text-decoration: none;
}

.cb-subhead.event-title {
	margin-bottom: 0.25rem;
}

.cb-static-link-block {
	width: 33.33%;
	margin-bottom: 1rem;
	text-decoration: none;
}

.home-content-wrapper {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3rem;
	padding-left: 3rem;
}

.home-content-wrapper.footer-wrapper {
	max-width: 1300px;
}

.spotlight-content-wrapper p,
.paragraph-extra-lineheight.sponsor-paragraph {
	margin-right: 1rem;
}

.cb-bkgd-image {
	height: 265px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

.cb-content-indented {
	padding-right: 1rem;
	padding-left: 0.5rem;
}

.news-signup-form-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

input[type="text"].news-signup-field {
	width: 100%;
	margin-bottom: 0px;
	margin-left: 0rem;
	padding: 8px 12px 8px 2px;
	border-style: none;
	background-color: rgba(33, 33, 33, 0.6);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 0.9rem;
	line-height: 1.4rem;
	color: #fff;
}

input[type="text"].news-signup-field:focus {
	padding-left: 12px;
	background-color: rgba(255, 255, 25, 0.1);
	color: #fff;
}

input[type="text"].news-signup-field::-webkit-input-placeholder {
	color: #fff;
	opacity: 1;
}

input[type="text"].news-signup-field:-ms-input-placeholder {
	color: #fff;
	opacity: 1;
}

input[type="text"].news-signup-field::placeholder {
	color: #fff;
	opacity: 1;
}

.cta-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	height: 400px;
	min-height: 300px;
	padding: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #757575;
	color: #fff;
	text-align: center;
	text-decoration: none;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}


.cta-link-block .cb-heading.cta-heading span {
	border-bottom: 1px solid transparent;
}


.cta-link-block:hover .cb-heading.cta-heading span {
	border-bottom: 1px solid rgba(243, 242, 238, 0.5);
}

.cb-heading.cta-heading {
	opacity: 0.9;
	color: #fff;
	font-size: 2.014rem;
}

.cta-content {
	overflow: hidden;
	height: 0px;
	margin-top: 20px;
	margin-bottom: 20px;
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.footer-paragraph {
	margin-bottom: 0rem;
	color: #f4f4f4;
	font-size: 0.9rem;
	line-height: 1.65rem;
}

.footer-paragraph.with-padding {
	padding-right: 15px;
}


.login-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 2.5rem;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.login-link {
	margin-right: 0rem;
	margin-left: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #f4f4f4;
	font-size: 0.8rem;
	line-height: 1.4rem;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.login-link:hover {
	color: #69b5a4;
	cursor: pointer;
}

.login-link.account {
	padding-left: 1.5rem;
	background-image: url('/20181011160742/assets/images/profile_gray-01.svg');
	background-position: 0px 50%;
	background-size: 16px 20px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #212121;
}

.login-link.account:hover {
	background-image: url('/20181011160742/assets/images/profile_white-01.svg');
	color: #fff;
}

.login-link.cart {
	padding-left: 1.5rem;
	background-image: url('/20181011160742/assets/images/cart_gray-01.svg');
	background-position: 0px 50%;
	background-size: 16px 20px;
	background-repeat: no-repeat;
	-webkit-transition-property: all;
	transition-property: all;
	color: #212121;
}

.login-link.cart:hover {
	background-image: url('/20181011160742/assets/images/cart_white-01.svg');
	color: #fff;
}

.footer-nav-item {
	margin-top: 0rem;
}

.footer-list-item-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #f4f4f4;
	text-decoration: none;
	text-transform: capitalize;
}

.footer-list-item-link:hover {
	border-bottom: 1px solid transparent;
	color: #bdbdbd;
}

.footer-list-item-link.w--current,
.footer-list-item-link.selected {
	border-bottom: 1px solid rgba(136, 136, 136, 0.5);
	color: #bdbdbd;
}

.footer-nav-list {
	margin-top: 0rem;
	font-size: 0.9rem;
	line-height: 1.65rem;
}

.event-date-wrapper {
	position: absolute;
	left: 0px;
	top: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 98px;
	height: 90px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: hsla(0, 0%, 100%, 0.85);
}

.event-month {
	color: #0a706b;
	font-size: 0.9rem;
	line-height: 1.35rem;
	font-weight: 600;
	text-align: center;
}

.event-date-big {
	color: #0a706b;
	font-size: 2.286rem;
	line-height: 2rem;
	font-weight: 700;
	text-align: center;
}

.event-details {
	color: #2d3047;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-style: italic;
	font-weight: 400;
}

.spotlight-content-wrapper {
	display: block;
}

#news_module.homepage_contentbox .cms_metadata2.cms_date h3,
.post-date {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #60698e;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
}

.slide-image-wrap {
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.event-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.footer-email-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #f4f4f4;
	font-size: 0.9rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.footer-email-link:hover {
	border-bottom: 1px solid rgba(136, 136, 136, 0.5);
	color: #bdbdbd;
}

.mobile-menu-arrow {
	display: none;
}

.inside-page-section {
	padding-top: 200px;
	background-color: #eee;
	background-image: url('/20181011160742/assets/images/square-pattern.png');
	background-position: 0px 0px;
	background-size: 150px;
}

.intro_paragraph,
.intro-paragraph {
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

.page-title-wrapper {
	margin-bottom: 1rem;
}

#news_module.homepage_contentbox .cms_title h3 {
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#news_module.homepage_contentbox .cms_title h3 a,
.header-text-link {
	display: inline-block;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #60698e;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#news_module.homepage_contentbox .cms_title h3 a:hover,
.header-text-link:hover {
	color: #60698e;
	cursor: pointer;
}

.left-sidebar-custom a,
.content-wrapper a,
.text-link,
.homeboxes a:not(.button):not(.primary):not(.secondary):not(.tertiary) {
	padding-bottom: 1px;
	border-bottom: 1px solid rgba(167, 167, 167, 0.5);
	color: #cd752f;
	line-height: 1.7rem;
	text-decoration: none;
}

.left-sidebar-custom a:hover,
.content-wrapper a:hover,
.text-link:hover,
.homeboxes a:not(.button):not(.primary):not(.secondary):not(.tertiary):hover {
	border-bottom-color: transparent;
	color: #60698e;
}

.content-wrapper h1 a,
.content-wrapper h2 a,
.content-wrapper h3 a,
.content-wrapper h4 a,
.content-wrapper h5 a,
.content-wrapper h6 a,
.homeboxes h1 a,
.homeboxes h2 a,
.homeboxes h3 a,
.homeboxes h4 a,
.homeboxes h5 a,
.homeboxes h6 a {
	color: #60698e !important;
	border-bottom: 1px solid rgba(167, 167, 167, 0.5) !important;
}

.content-wrapper h1 a:hover,
.content-wrapper h2 a:hover,
.content-wrapper h3 a:hover,
.content-wrapper h4 a:hover,
.content-wrapper h5 a:hover,
.content-wrapper h6 a:hover,
.homeboxes h1 a:hover,
.homeboxes h2 a:hover,
.homeboxes h3 a:hover,
.homeboxes h4 a:hover,
.homeboxes h5 a:hover,
.homeboxes h6 a:hover {
	color: #3b3b6b !important;
	border-bottom: 1px solid transparent !important;
}

.breadcrumbs-list-item {
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	float: left;
	border-right: 1px solid #bdbdbd;
}

.breadcrumbslink-lr {
	border-bottom: 1px solid rgba(167, 167, 167, 0.5);
	color: #60698e;
	text-decoration: none;
}

.breadcrumbslink-lr:hover {
	color: #738ac0;
	border-bottom: 1px solid transparent;
}

.newsletter-button {
	display: inline-block;
	width: 2rem;
	height: 2.5rem;
	background-color: rgba(33, 33, 33, 0.6);
	background-image: url('/20181011160742/assets/images/white-arrow-rounded.png');
	background-position: 50% 50%;
	background-size: 7px;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition: background-color 300ms ease;
	transition: background-color 300ms ease;
	border: 0 none;
	text-indent: -9999px;
	cursor: pointer;
}

.newsletter-button:hover {
	background-color: #60698e;
	cursor: pointer;
}

.success-message {
	background-color: rgba(105, 181, 164, 0.35);
	font-weight: 600;
	text-align: left;
}

.error-message {
	padding: 20px;
	background-color: rgba(178, 43, 37, 0.2);
	font-weight: 600;
}

.footer-signoff-link-wrap {
	display: inline-block;
	margin-left: 0.75rem;
}

.footer-signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	opacity: 1;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #bdbdbd;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link:hover {
	border-bottom: 1px solid rgba(136, 136, 136, 0.5);
	color: #f3f2ee;
}

.footer-signoff-link.w--current {
	color: #6bb4b1;
	cursor: pointer;
}

.signoff-left-wrap {
	display: inline-block;
	color: #bdbdbd;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.split-50 {
	width: 50%;
}

._50-50-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper-2.footer-socmed-wrapper {
	margin-top: 1rem;
}

.social-media-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #cd752f;
	text-align: center;
}

.social-media-link:hover {
	background-color: #955623;
	cursor: pointer;
}

.header-link {
	padding-bottom: 3px;
	border-bottom: 1px solid #a7a7a7;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a706b;
	text-decoration: none;
}

.header-link:hover {
	padding-bottom: 3px;
	border-bottom-color: transparent;
	color: #06413d;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-size: 1.1rem;
}

.screen-message.error {
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-size: 1.1rem;
}

.screen-message-text {
	font-weight: 500;
}

.right-col-link {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box,
.right-col-item-wrap {
	margin-bottom: 2rem;
}

.clients-slider {
	position: relative;
	height: 80px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-style: none;
	border-width: 1px;
	border-color: rgba(111, 124, 136, 0.15);
	background-color: transparent;
}

.clients-slider-mask {
	position: relative;
	height: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
}

.clients-slider-slide {
	position: relative;
	width: 20%;
	height: 60px;
	min-width: 100px;
	margin-top: 10px;
}

.clients-slider-slide.slide-1 {
	border-left: 1px solid rgba(111, 124, 136, 0.07);
}

.clients-slider-image {
	position: relative;
	top: 50%;
	display: block;
	max-height: 50px;
	max-width: 100px;
	margin-right: auto;
	margin-left: auto;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

#partners_module .partners-arrow {
	border-color: #01579b;
	width: 13px;
	height: 13px;
}

#partners_module .arrow-icon-right {
	left: 5px;
	top: 33px;
}

#partners_module .arrow-icon-left {
	top: 33px;
}

.clients-slider-arrow {
	z-index: 21;
	height: 100%;
	max-width: 2rem;
	-webkit-transition: background-color 300ms ease, color 300ms ease;
	transition: background-color 300ms ease, color 300ms ease;
	color: #01579b;
	font-size: 1.25rem;
}

#partners_module .partners-cycle-carousel-previous,
#partners_module .partners-cycle-carousel-next {
	-webkit-transition: background-color 300ms ease, color 300ms ease;
	transition: background-color 300ms ease, color 300ms ease;
}

#partners_module .partners-cycle-carousel-previous:hover,
#partners_module .partners-cycle-carousel-next:hover,
.clients-slider-arrow:hover {
	background-color: #e2e2e2;
	color: #1f304f;
	cursor: pointer;
}

#partners_module .partners-cycle-carousel-previous:hover .partners-arrow,
#partners_module .partners-cycle-carousel-next:hover .partners-arrow {
	border-color: #1f304f;
}

.footer-left-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
}

blockquote,
.block-quote {
	border-left-color: rgba(147, 175, 241, 0.5);
	background-color: rgba(245, 245, 245, 0.75);
	color: #3b3b6b;
}

.news-signup-form-block {
	width: 100%;
	max-width: 225px;
	margin-top: 1rem;
	margin-bottom: 0px;
}

.link {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.footer-right-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
}

.left-nav-list-item-2 {
	margin-bottom: 0rem;
}

.left-nav-list-item-2.greatgrandchild-li {
	margin-left: 0.5rem;
}

.left-nav-list-item-2.grandchild-li {
	margin-left: 1rem;
}

.left-nav-link-wrap {
	border-bottom: 1px solid #e0ded6;
}

.left-nav-list-2 {
	margin-top: 0rem;
	margin-bottom: 0rem;
	background-color: transparent;
	font-weight: 400;
}

.left-nav-list-2 .left-nav-list-item-2:last-of-type .left-nav-link-wrap {
	border-bottom: 0 none;
}

.footer-phone-link {
	display: none;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.286rem;
		line-height: 2.986rem;
	}

	.footer-phone-link {
		display: inline-block;
	}

	.footer-phone-text {
		display: none;
	}

	.main-content {
		width: 100%;
	}

	.container {
		max-width: 991px;
	}

	.container.content-container {
		display: block;
		max-width: 991px;
	}

	.container.brand-container {
		display: block;
		max-width: none;
	}

	.container.nav-container {
		display: block;
		max-width: 940px;
		margin-top: 0px;
	}

	.container.home-content-container {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.top-nav-container {
		max-width: 991px;
		padding-right: 1rem;
	}

	.container.footer-content-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.container.events-container {
		max-width: 991px;
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.container.cta-container {
		max-width: 991px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.left-nav-col {
		display: none;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.breadcrumb-current {
		margin-top: 0rem;
	}

	.content-wrapper {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 1rem;
		margin-left: 0rem;
		padding: 1rem 1.25rem;
		background-color: #f4f4f4;
	}

	.right-side-col h2 {
		margin-top: 1rem;
	}

	.right-side-col p {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.right-side-col h3 {
		margin-top: 1rem;
	}

	.right-side-col h4 {
		margin-top: 1rem;
	}

	.navbar {
		padding-right: 1rem;
		padding-left: 2rem;
	}

	.brand {
		width: 225px;
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.brand.w--current {
		width: 225px;
	}

	.nav-menu {
		display: block;
		width: 90%;
		margin-top: 105px;
		background-color: #0a706b;
		text-align: left;
	}

	.main-nav-link {
		padding: 1.3rem 1rem 1rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		color: #fff;
		line-height: 1rem;
		font-weight: 600;
	}

	.main-nav-link:hover {
		background-color: #408274;
		color: #fff;
		cursor: pointer;
	}

	.main-nav-link.w--current {
		background-color: #fff;
		color: #0a706b;
		cursor: pointer;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
	}

	.dropdown-wrapper {
		display: none;
	}

	.rotator {
		height: auto;
	}

	.slideshow-arrow {
		top: 57px;
		width: 60px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slidenav {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 0px;
	}

	.slide-caption-wrapper {
		position: static;
		top: 0%;
		display: block;
		overflow: visible;
		width: 100%;
		max-width: none;
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 3rem 3rem;
		background-color: #e2e2e2;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slide-caption-header {
		font-size: 2.014rem;
		line-height: 2.714rem;
		text-decoration: none;
	}

	.slide-caption-paragraph p {
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	.footer-section,
	.home-content-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.footer-section {
		padding-bottom: 0;
	}

	.home-content-section.home-content-section-pattern-bg {
		padding-top: 1.5rem;
		padding-bottom: 4rem;
		border-top-color: #e2e2e2;
	}

	.home-content-section.two-cb-home-content-section {
		padding-bottom: 4rem;
		border-top-color: #e2e2e2;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.cb-static._50percent-left {
		width: 100%;
		padding-right: 0rem;
	}

	.cb-static._50percent-right {
		width: 100%;
		padding-left: 0rem;
	}

	.cb-static.footer-cb-3 {
		width: 50%;
		padding-right: 5rem;
	}

	.cb-static.footer-cb-4 {
		display: block;
	}

	.event-image {
		margin-bottom: 0.75rem;
		background-image: none;
	}

	.footer-grip-link {
		margin-top: 1rem;
	}

	.copyright-paragraph {
		margin-top: 1rem;
	}

	.cb-heading.clients-cb-head {
		font-size: 2.014rem;
		line-height: 2.714rem;
	}

	.cb-heading.cta-heading {
		font-size: 2rem;
		line-height: 2.9rem;
	}

	.cb-heading.footer-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding-left: 2.5rem;
	}

	.rotator-arrow {
		position: relative;
		left: 0px;
		right: 0px;
		bottom: 0px;
		width: 40px;
		height: 40px;
		opacity: 0.35;
	}

	.rotator-arrow:hover {
		opacity: 0.6;
	}

	.rotator-arrow.rotator-left-arrow {
		width: 40px;
		height: 40px;
	}

	.cb-subhead.event-title {
		margin-bottom: 0.125rem;
		font-size: 1.286rem;
		line-height: 1.986rem;
	}

	.cb-static-link-block {
		width: 33.33%;
		margin-bottom: 2rem;
	}

	.home-content-wrapper {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 2rem;
		padding-left: 2.5rem;
	}

	.cta-link-block {
		height: auto;
	}

	.login-link.account {
		margin-right: 1rem;
	}

	.footer-nav-list {
		margin-bottom: 0rem;
	}

	.event-date-wrapper {
		width: 84px;
		height: 74px;
	}

	.event-details {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.menu-button {
		position: static;
		top: 25%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 68px;
		height: 68px;
		padding: 10px;
		background-color: transparent;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transform: translate(0px, 25%);
		-ms-transform: translate(0px, 25%);
		transform: translate(0px, 25%);
		-webkit-transition: background-color 300ms ease, margin-top 300ms ease;
		transition: background-color 300ms ease, margin-top 300ms ease;
		color: #3b3b6b;
	}

	.menu-button:hover {
		background-color: #60698e;
		color: #fff;
		cursor: pointer;
	}

	.mm-wrapper_background .menu-button {
		background-color: #60698e;
		color: #fff;
		cursor: pointer;
	}

	.mobile-menu-icon {
		font-size: 2.5rem;
		line-height: 2.5rem;
		text-align: center;
	}

	.menu-button-text {
		margin-top: -8px;
		font-size: 0.7rem;
		line-height: 1rem;
		text-align: center;
	}

	.slide-image-wrap {
		height: 375px;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slide-mask {
		background-color: #2d3047;
		white-space: nowrap;
	}

	.event-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.mobile-link-label {
		display: inline-block;
		margin-bottom: 0rem;
		line-height: 1rem;
	}

	.mobile-menu-arrow {
		display: block;
		width: 12%;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		border-bottom-color: rgba(31, 48, 79, 0.2);
		background-color: #0a706b;
		background-image: url('/20181011160742/assets/images/white-arrow-rounded.png');
		background-position: 50% 50%;
		background-size: 8px;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow:hover {
		background-color: #69b5a4;
		cursor: pointer;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.two-part-mobile-nav-wrapper:hover {
		color: #2d3047;
		cursor: pointer;
	}

	.inside-page-section {
		padding-top: 150px;
	}

	._50-50-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.social-media-wrapper-2.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.social-media-link {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
	}

	.clients-slider {
		margin-right: 0.75rem;
		margin-left: 0.75rem;
	}

	.clients-slider-slide {
		width: 25%;
	}

	.footer-left-content-wrap {
		width: 100%;
	}

	.footer-right-content-wrap {
		width: 100%;
	}

	.content-col {
		width: 100%;
	}

	.cta-content {
		height: auto !important;
		opacity: 1 !important;
		transform: translateX(0px) translateY(00px) translateZ(0px) !important;
	}

	.cb-heading.cta-heading {
		opacity: 1 !important;
		transform: translateX(0px) translateY(0px) translateZ(0px) !important;
	}
}

@media (max-width: 767px) {
	.home-content-section.sponsors-section .container {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	ul {
		padding-left: 1rem;
	}

	ol {
		padding-left: 1.25rem;
	}

	.container {
		max-width: 767px;
	}

	.container.content-container {
		max-width: 767px;
	}

	.container.brand-container {
		max-width: 728px;
	}

	.container.nav-container {
		max-width: 728px;
	}

	.container.home-content-container {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.container.footer-content-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.events-container {
		max-width: 767px;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container.cta-container {
		max-width: 767px;
	}

	.breadcrumb-current {
		margin-top: 0rem;
	}

	.navbar {
		padding-right: 1rem;
		padding-left: 0.35rem;
	}

	.brand {
		margin-left: 0.65rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 88%;
	}

	.rotator {
		height: auto;
	}

	.slideshow-arrow {
		top: 25px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slide-caption-wrapper {
		max-width: 100%;
	}

	.slide-caption-header {
		font-size: 1.714rem;
		line-height: 2.414rem;
	}

	.home-content-section.home-content-section-pattern-bg {
		border-top-width: 2px;
		border-top-color: #e2e2e2;
	}

	.home-content-section.two-cb-home-content-section {
		padding-bottom: 2rem;
		border-top-color: #e2e2e2;
	}

	.cb-static {
		width: 33.33%;
	}

	.cb-static.footer-cb-1 {
		width: 50%;
	}

	.cb-static.footer-cb-3 {
		padding-right: 2rem;
	}

	.cb-static.footer-cb-left-copy {
		width: 50%;
	}

	.footer-grip-link {
		display: block;
		margin-top: 2rem;
		margin-right: 0px;
		margin-left: 0px;
		float: none;
	}

	.copyright-paragraph {
		margin-top: 1rem;
		margin-right: 0.5rem;
	}

	.cb-heading.clients-cb-head {
		line-height: 2.714rem;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding-left: 0rem;
	}

	.rotator-arrow {
		position: relative;
		top: -63px;
		background-size: 25px;
	}

	.rotator-arrow.rotator-left-arrow {
		background-size: 25px;
	}

	.cb-static-link-block {
		width: 100%;
		margin-bottom: 2rem;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 1.5rem;
		padding-left: 2rem;
	}

	.spotlight-content-wrapper p,
	.paragraph-extra-lineheight.sponsor-paragraph {
		margin-right: 0rem;
	}

	.cta-link-block {
		width: 100%;
		height: auto;
	}

	.cta-link-block.cta-1 {
		height: 300px;
		background-size: auto, cover;
	}

	.cta-link-block.cta-1:hover {
		background-size: auto, cover;
	}

	.cta-link-block.cta-2 {
		width: 100%;
		background-size: auto, cover;
	}

	.cta-link-block.cta-2:hover {
		background-size: auto, cover;
	}

	.cta-link-block.cta-3 {
		width: 101%;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		background-size: auto, cover;
	}

	.cta-link-block.cta-3:hover {
		background-size: auto, cover;
	}

	.login-wrapper {
		border-right-color: hsla(0, 0%, 100%, 0);
	}

	.footer-nav-item {
		margin-top: 0rem;
		margin-bottom: 0.5rem;
	}

	.footer-nav-list {
		margin-left: 0rem;
	}

	.event-date-wrapper {
		width: 90px;
		height: 80px;
	}

	.slide-image-wrap {
		height: 225px;
	}

	.mobile-menu-arrow {
		width: 12%;
	}

	.breadcrumbs-list {
		display: block;
		margin-bottom: 0.35rem;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-left: 0rem;
	}

	.social-media-link {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.clients-slider-slide {
		width: 33.33%;
	}

	.footer-left-content-wrap {
		width: 100%;
		border-bottom: 1px solid rgba(255, 248, 225, 0.25);
	}

	.footer-right-content-wrap {
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid rgba(255, 248, 225, 0.25);
	}
}

@media (max-width: 479px) {
	.home-content-section.sponsors-section .container {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	h1 {
		font-size: 1.714rem;
		line-height: 2.414rem;
	}

	.container {
		max-width: none;
	}

	.container.content-container {
		max-width: none;
		padding-bottom: 2rem;
	}

	.container.brand-container {
		margin-left: 0px;
	}

	.container.nav-container {
		max-width: none;
	}

	.container.signoff-container {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.container.top-nav-container {
		padding-right: 0.75rem;
		padding-left: 1rem;
	}

	.container.footer-content-container {
		text-align: center;
	}

	.container.events-container {
		max-width: none;
	}

	.container.cta-container {
		max-width: none;
	}

	.navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		padding-left: 0.5rem;
	}

	.logo {
		display: block;
		width: 225px;
		margin-right: auto;
		margin-left: 6px;
	}

	.brand {
		width: 200px;
		height: 65px;
		margin-top: 0.75rem;
		margin-right: 0.5rem;
		margin-bottom: 0.75rem;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.brand.w--current {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.nav-menu {
		margin-top: 135px;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
	}

	.slideshow-arrow {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.slide-caption-wrapper {
		margin-right: 1.5rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.slide-caption-header {
		font-size: 1.5rem;
		line-height: 2.2rem;
	}

	.slide-caption-paragraph p {
		margin-bottom: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	.footer-section,
	.home-content-section {
		padding-top: 1rem;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.cb-static._50percent-left {
		padding-top: 0rem;
	}

	.cb-static._50percent-right {
		padding-bottom: 0rem;
	}

	.cb-static.footer-cb-1 {
		width: 100%;
	}

	.cb-static.footer-cb-3 {
		width: 100%;
		padding-top: 1rem;
		padding-right: 0rem;
	}

	.cb-static.footer-cb-left-copy {
		width: 100%;
	}

	.cb-static.footer-cb-4 {
		width: 100%;
	}

	.event-image.event-image-3 {
		height: 165px;
	}

	.event-image.event-image-2 {
		height: 165px;
	}

	.event-image.event-image-1 {
		height: 165px;
	}

	.footer-grip-link {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.copyright-paragraph {
		margin-right: 0rem;
	}

	.cb-subhead.event-title {
		font-size: 1.143rem;
		line-height: 1.843rem;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.login-wrapper {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.login-link {
		font-weight: 400;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
	}

	.footer-nav-list {
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.event-date-wrapper {
		width: 84px;
		height: 74px;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.menu-button {
		position: relative;
		top: 0%;
		bottom: 15px;
		width: 54px;
		height: 54px;
		padding: 8px 6px;
	}

	.menu-button-text {
		display: none;
		font-size: 0.7rem;
	}

	.slide-image-wrap {
		height: 200px;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.intro_paragraph,
	.intro-paragraph {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.footer-signoff-link {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.split-50 {
		width: 100%;
	}

	._50-50-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.social-media-wrapper-2.footer-socmed-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.clients-slider {
		height: 125px;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.clients-slider-slide {
		width: 100%;
		height: 125px;
		margin-top: 0px;
	}

	.clients-slider-slide.slide-1 {
		height: 120px;
	}

	.clients-slider-image {
		max-height: 75px;
		max-width: 150px;
	}

	.footer-left-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.news-signup-form-block {
		max-width: none;
	}

	.footer-right-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-page-section {
		padding-top: 133px;
	}

	.footer-paragraph.with-padding {
		padding-right: 0px;
	}
}

html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

/* CONTROL PHONE NUMBER COLOR */
.footer-section a[href^=tel] {
	color: #f4f4f4 !important;
}

/* Mozilla based browsers */
::-moz-selection {
	background-color: #000000;
	color: #ffffff;
	opacity: 1;
}

/* Safari browser */
::selection {
	background-color: #000000;
	color: #ffffff;
	opacity: 1;
}

/* Adjust header on narrower desktop views */
@media screen and (min-width: 1036px) and (max-width: 1140px) {
	.container.top-nav-container {
		padding-right: 1.4rem;
		padding-left: 1.4rem;
	}

	.navbar {
		padding-right: 1.4rem;
		padding-left: 1.4rem;
	}
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
	.brand {
		width: 185px;
		margin-right: 0px;
		padding: 19px 0;
	}
}

@media screen and (min-width: 992px) and (max-width: 1035px) {
	.nav-menu {
		margin-left: 0rem;
	}

	.container.top-nav-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.navbar {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
	.main-nav-link {
		padding-left: 0.1rem;
		padding-right: 0.1rem;
		font-size: 0.8rem;
	}
}

/* Adjust logo on super small mobile */
@media screen and (max-width: 330px) {
	.brand {
		width: 180px;
		margin-left: 0.25rem;
	}
}

/* Sticky/Flexy Header */
.sticky .newssignup-social-container {
	max-height: 0;
	opacity: 0;
}

.sticky .brand {
	height: 78px;
	width: 221px;
	margin-top: 2px;
}

.sticky .main-nav-link {
	font-size: 0.9rem;
}

.w-slider-dot {
	width: 46px;
	height: 6px;
}

.rotator-section {
	margin-top: 130px;
}

.sticky .menu-button {
	margin-top: -10px;
}

@media (max-width: 479px) {
	.rotator-section {
		margin-top: 133px !important;
	}
}

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}

	.mm-menu_offcanvas {
		background-color: #3b3b6b;
		max-width: none;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 34px;
	}

	.mm-menu li {
		display: block !important;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1.3rem 1rem 1rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		text-transform: uppercase;
		float: none;
		box-shadow: none;
		line-height: 1rem;
		font-weight: 600;
		font-size: 1rem;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-listitem_selected > a.w--current:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span.w--current {
		background-color: #fff;
		color: #cd752f;
		cursor: pointer;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: hsla(0, 0%, 100%, 0.25);
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: hsla(0, 0%, 100%, 0.9);
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 44%;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
		background: #2b3b56;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
		margin-right: 20%;
		width: 80%;
	}

	.mm-menu li.active .nav-link {
		background-color: #fff;
		color: #cd752f;
	}

	.mm-navbar {
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		padding: 0 10px 0 40px;
		height: 54px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		height: 54px;
		border-bottom: 0 none !important;
	}

	.mm-navbar__title {
		font-size: 1rem;
		line-height: 1rem;
		font-weight: 600;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}

	.mm-navbar:hover {
		cursor: pointer;
	}

	.mm-navbar:hover .mm-navbar__title {
		color: #fff;
		cursor: pointer;
	}

	.rotator-section {
		margin-top: 147px;
	}
}

/*inside page*/
/*right sidebar*/
.box.documents .content > table {
	margin-bottom: 15px;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 15px;
}

/*messages*/
#session-message-wrapper .success {
	display: block;
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #2e2b2b;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 500;
}

#session-message-wrapper .error {
	display: block;
	border-left: 6px solid #c70616;
	background-color: #ffe1e1;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #2e2b2b;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 500;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-bottom: 0;
	margin-top: 0;
	font-weight: 500;
	font-size: 1rem;
	color: #2e2b2b;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 0 0 0.75rem 0;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: inherit;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #3b3b6b;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: inherit;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #60698e;
	color: white;
}

table.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	box-shadow: none;
	border-bottom: 0 none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
	box-shadow: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #e4e4e4;
}

table.styled.striped tr:nth-child(odd) {
	background: transparent;
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	color: #333333;
}

textarea {
	resize: vertical;
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: #c9c6c4 !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: #c9c6c4;
	border: 0px;
	color: #c9c6c4;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}
